import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


const API_KEY = environment.googleMaps;

@Injectable({
  providedIn: 'root',
})
export class AppReverseGeocodeService {
  constructor(
    private http: HttpClient,
  ) { }

  getReverseGeocode$(lat: number, lng: number) {
    // eslint-disable-next-line max-len
    const uri = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${encodeURIComponent(lat)},${encodeURIComponent(lng)}&key=${API_KEY}`;

    return this.http.get(uri).pipe(
      map((response: any) => {
        if (response.status !== 'OK') {
          return undefined;
        }
        const results = response.results.map((r) => {
          const responseLat = r.geometry.location.lat;
          const responseLng = r.geometry.location.lng;

          return {
            lat: responseLat,
            lng: responseLng,
            formattedAddress: r.formatted_address,
          };
        });

        return results.length > 0 ? results[0] : undefined;
      }),
    );
  }
}

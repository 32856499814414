
import { computed, DestroyRef, inject, Injectable, Signal, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { APP_STATE, AppReverseGeocodeService } from '@gth-legacy';
import { DefaultCity } from '@index/interfaces';
import { Store } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models/user';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { BehaviorSubject, map, of, switchMap } from 'rxjs';

const DEFAULT_LOCATION: ()=> DefaultCity = (()=>({
    name: 'Seattle, Wa, USA',
    lat: 47.608013,
    lng: -122.335167,
}));

@Injectable({ providedIn: 'root' })
export class GuestUserLocationService {
    private location: Signal<DefaultCity>;
    public writablelocation = signal<DefaultCity| null>(null);
    private store = inject(Store<APP_STATE>);
    private safeWindowService = inject(SrvSafeWindowService);
    private geocodeService = inject(AppReverseGeocodeService);
    private currentUser = signal<GthUserModel| null>(null);
    readonly #destroyRef = inject(DestroyRef);

    constructor() {
        this.init();
    }

    private init() {
        toObservable(this.store.selectSignal(selectUser)).subscribe((s)=>{
            this.currentUser.set(s);
        });
        this.location = this.getCurrentCity(this.currentUser, this.writablelocation);
    }

    private getCurrentCity(
        user: Signal<GthUserModel| null>,
        locationSetByUser: Signal<DefaultCity | null>,
        ): Signal<DefaultCity> {
       return computed(()=>{
            if (locationSetByUser()) return locationSetByUser();
            return user() && user().defaultCity? user().defaultCity :
            this.getSearchLocation()();
        });
    }

    getSearchLocation() {
      const locationSignal = signal(DEFAULT_LOCATION());
       const sub$ = this.locationPermission
        .subscribe((location)=>{
            if (!location) return;
            const { latitude, longitude } = location.coords;

            return this.geocodeService
                .getReverseGeocode$(latitude, longitude)
                .pipe(map((geoCode)=>{
                    locationSignal.set({
                        name: geoCode.formattedAddress,
                        lat: geoCode.lat,
                        lng: geoCode.lng,
                    });
                }));
        });

        this.#destroyRef.onDestroy(() => sub$.unsubscribe());
        return locationSignal;
    }

    get locationPermission() {
        const subject =
            new BehaviorSubject<GeolocationPosition | null>(null);
            if (typeof this.safeWindowService.navigator?.permissions?.query !== 'function') {
                return subject;
            }
            this.safeWindowService.navigator?.permissions?.query({ name: 'geolocation' })
                .then((result) => {
                    this.getPostionAsPromise(result).then((position)=>{
                        subject.next(position);
                    });
                    result.addEventListener('change', () => {
                        this.getPostionAsPromise(result).then((position)=>{
                            subject.next(position);
                    });
                });
            });
        return subject;
    }

   async getPostionAsPromise(result: PermissionStatus):Promise<GeolocationPosition | null> {
        return await new Promise((resolve)=>{
            if (result.state === 'granted') {
                this.getPosition().then((position) => {
                    resolve(position);
                });
            } else if (result.state === 'denied') {
                resolve(null);
            }
        });
    }

    getPosition(): Promise<GeolocationPosition> {
        try {
            return new Promise((resolve, reject) =>
            navigator
                .geolocation
                .getCurrentPosition(resolve, reject));
          } catch (err) {
            console.error(err);
          };
    }

    set currentLocation(city:DefaultCity) {
        this.writablelocation.set(city);
    }

    get currentLocationSignal() {
        return computed(()=>{
          return this.writablelocation() ||
          this.location();
});
    }
}

import { computed, inject, Injectable } from '@angular/core';
import { DefaultCity } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models';
import firebase from 'firebase/compat/app';

import { GUEST_PROFILE } from '../mocks/profile';
import { GuestUserLocationService } from './guest-user-location.service';

export const GUEST_PROFILE_ID = 'guest-profile-id';


@Injectable({
  providedIn: 'root',
})
export class GuestUserService {
  locationService = inject(GuestUserLocationService);

constructor() { }

getUserAsSignal(id = GUEST_PROFILE_ID) {
  return computed(()=>{
    const city = this.locationService.currentLocationSignal;
    return this.createGuestUser(id, city());
  });
}

private createGuestUser(id:string, defaultCity: DefaultCity) {
  return new GthUserModel(
    id,
    {
      uid: id,
      displayName: 'Guest',
      fullName: 'Guest',
      email: 'guest@gametimehero.fake',
      token: 'fake-token',
      photoURL: 'assets/avatars/guest-icon.png',
      emailVerified: true,
      defaultCity,
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
    },
  );
}
}

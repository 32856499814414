import { inject, Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { PlayerListFactory } from '@index/daos/utils/player-list-factory';
import { Availability, Connection, ListFilter, User } from '@index/interfaces';
import { UserMapper } from '@index/mappers/user-mapper';
import { UserModel } from '@index/models/user';
import { DBUtil } from '@index/utils/db-utils';
import { GthUserModel, SrvAvailabilityModel } from '@sentinels/models';
import { getAuth, updateProfile } from 'firebase/auth';
import { combineLatest, filter, from, lastValueFrom, Observable, of, zip } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take } from 'rxjs/operators';

import { FirestoreService } from '../core/firebase.service';
import { applyLocationMapsBounds, generateBoundsFromLatLng } from '../helpers/geobounds';

export function replaceUndefinedWithNull(obj: any) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      replaceUndefinedWithNull(obj[key]); // Recursively handle nested objects
    } else if (obj[key] === undefined) {
      obj[key] = null; // Replace undefined with null
    }
  }
  return obj;
}

export interface UserReadFilter {
  email?: string;
  id?: string;
  phone?: string;
}

@Injectable({ providedIn: 'root' })
export class UserService extends FirestoreService<User> {
  private functions = inject(AngularFireFunctions);

  protected basePath: string = 'users';
  private auth = getAuth();

  private mapper = new UserMapper();
  private playerListFactory = new PlayerListFactory(this.firestore as any);

  getUsersByCheckIn(placeId: string): Observable<GthUserModel[]> {
    return this.firestore
      .collection<User>('users', (ref) => ref.where('checkIn', 'array-contains', { placeId }))
      .get()
      .pipe(
        map((snapshot) =>
          snapshot.docs.map((doc) => {
            const userData = doc.data() as User;
            return new GthUserModel(userData.uid, userData);
          }),
        ),
      );
  }

  getUsers(userIds: string[]): Observable<GthUserModel[]> {
    const userObservables = userIds.map((id) => this.getUser$(id));
    if (userObservables.length === 0) {
      return of([]);
    }

    return zip(...userObservables).pipe(shareReplay(1));
  }

  getUsersByLocation$(
    lat: number,
    lng: number,
    withAvailability?: boolean,
    bounds?: google.maps.LatLngBoundsLiteral,
  ): Observable<GthUserModel[]> {
    bounds = bounds || generateBoundsFromLatLng(lat, lng, 200);

    interface UserWithId extends User {
      id: string;
    }

    const users$ = from(
      this.firestore
        .collection(DBUtil.User, (ref) => applyLocationMapsBounds(ref, bounds, 'defaultCity'))
        .get(),
    ).pipe(
      map((snapshot) =>
        snapshot.docs.map((doc) => {
          const userData = doc.data() as UserWithId;
          return { ...userData, id: doc.id };
        }),
      ),
      shareReplay(1),
      map((users) =>
        users.map((user) => {
          return new GthUserModel(user.id, user);
        }),
      ),
      catchError((error) => {
        console.error('Firestore error:', error);
        return of([]); // Return an empty array or handle the error appropriately.
      }),
    );
    return users$.pipe(
      switchMap((users) => {
        if (!withAvailability) return of(users);

        const usersWithAvailability = users.map((user) => {
          const availability = this.getUserAvailability$(user.uid);
          return availability.pipe(
            map((availability) => {
              user.userAvailability = new SrvAvailabilityModel(user.uid, availability);
              return user;
            }),
          );
        });

        return combineLatest(usersWithAvailability);
      }),
    );
  }

  /**
   * Gets user by ID.
   * @param {string}userId
   * @return {Observable<GthUserModel>}
   */
  getUser$(userId: string): Observable<GthUserModel | null> {
    return this.doc$<User>(userId).pipe(
      map((user) => {
        return user?.uid ? new GthUserModel(user.uid, user) : null;
      }),
    );
  }

  /**
   * Gets user by id .
   * @param {string} userId Id for user
   * @param {User} user Firebase Auth User
   * @return {Observable<GthUserModel>} model of user
   */
  getUser(userId: string, user: User): Observable<GthUserModel> {
    return this.doc$<User>(userId).pipe(
      map((doc) => {
        const data = {
          ...doc,
        };

        data.uid = user.uid;
        data.email = user.email ?? '';
        data.photoURL = user.photoURL ?? '';
        data.emailVerified = user.emailVerified;
        return new GthUserModel(doc.uid ?? '', data);
      }),
    );
  }

  async getAsyncUser(userId: string) {
    const userDocRef = this.doc<User>(userId);

    const userSnapshot = await lastValueFrom(userDocRef.get());

    if (!userSnapshot.exists) return null;

    return new GthUserModel(userSnapshot.id, userSnapshot.data());
  }

  private getUserAvailability$(userId: string): Observable<Availability | null> {
    if (!userId) return of(null);

    const userAvailability = this.firestore
      .collection<Availability>('srv_user_availability')
      .doc(userId)
      .valueChanges({ idField: 'id' });

    return userAvailability;
  }

  // TODO(srevier): integrate with ngrx and cache
  getUserByEmail$(email: string): Observable<GthUserModel | null> {
    return this.read$({ email }).pipe(
      map((user) => (user ? new GthUserModel(user.uid, user) : null)),
    );
  }

  userExists$(email: string): Observable<boolean> {
    return this.read$({ email }).pipe(map((user) => !!user));
  }

  getUserByPhone$(phone: string): Observable<GthUserModel | null> {
    return this.read$({ phone }).pipe(
      filter(this._notNull),
      map((user) => new GthUserModel(user.uid, user)),
    );
  }

  getUserById$(id: string): Observable<GthUserModel | null> {
    return this.read$({ id }).pipe(
      filter(this._notNull),
      map((user) => new GthUserModel(user.uid, user)),
    );
  }

  updateOne(id: string, dataToUpdate: { [key: string]: any }) {
    return this.update(id, dataToUpdate);
  }

  deleteUser(id: string) {
    return this.delete(id);
  }

  async list(filter: ListFilter) {
    if (filter.lat !== undefined && filter.lng !== undefined) {
      return this.playerListFactory.getByLatLng(filter.lat, filter.lng);
    }

    const x = this.firestore.collection(this.basePath, (ref) => {
      if (!filter.email && !filter.displayName) {
        return ref.limit(100);
      }

      let value = filter.email ? filter.email : filter.displayName;

      const filterKey = filter.email ? 'email' : 'displayName';

      // Convert the value to lowercase for case-insensitive matching
      value = value?.toLowerCase() ?? '';

      // Generate the end code for substring matching
      // eslint-disable-next-line max-len
      const endCode =
        value.slice(0, -1) + String.fromCharCode(value.charCodeAt(value.length - 1) + 1);

      return (
        ref
          .orderBy(filterKey) // Order the results by the filter key for consistent behavior
          .where(filterKey, '>=', value) // Case-insensitive substring match from the start
          // eslint-disable-next-line max-len
          .where(filterKey, '<', endCode) // Case-insensitive substring match until the next character
          // eslint-disable-next-line max-len
          .where(filterKey, '>=', value.charAt(0).toUpperCase() + value.slice(1))
      ); // Case-insensitive matching of first character as uppercase
    });

    const docItem = await lastValueFrom(x.get());

    if (!docItem) return [];

    return docItem.docs.map((doc) => doc.data());
  }

  async createUser(userModel: UserModel) {
    const collectionRef = this.collection;

    const docRef =
      userModel.uid === null ? collectionRef.doc() : collectionRef.doc(userModel.uid);

    try {
      await docRef.set(this.mapper.toMap(userModel));

      if (!docRef) return undefined;

      const docRefItem = await lastValueFrom(docRef.get());

      if (!docRefItem.exists) return undefined;

      return docRefItem.id;
    } catch (e) {
      throw e;
    }
  }

  async updateUser(userModel: UserModel | GthUserModel) {
    const user = userModel instanceof GthUserModel ? (userModel.copy as UserModel) : userModel;
    const docRef = this.doc(userModel.uid);
    const mappedUser = replaceUndefinedWithNull(this.mapper.toMap(user));

    try {
      const updateFirebaseDoc = docRef.set(mappedUser, { merge: true });

      const authUser = this.auth.currentUser;
      updateProfile(authUser, { photoURL: userModel.photoURL });
      const updateAuth = this.functions.httpsCallable('users-triggers-updateFirebaseAuth');
      const updateRequest = {
        displayName: userModel.displayName,
        phoneNumber: userModel.phoneNumber,
        photoURL: userModel.photoURL,
      };

      const updateFirebaseAuth = lastValueFrom(updateAuth(updateRequest).pipe(take(1)));

      return Promise.all([updateFirebaseDoc, updateFirebaseAuth]).then(() => true);
    } catch (error: unknown) {
      throw error;
    }
  }

  read$(filter: UserReadFilter) {
    let type: 'email' | 'phone' | 'id';
    if (filter.email) type = 'email';
    else if (filter.id) type = 'id';
    else if (filter.phone) type = 'phone';

    return this.collection$(
      (ref) => {
        switch (type) {
          case 'email':
            return ref.where('email', '==', filter.email).limit(1);
          case 'phone':
            return ref.where('phoneNumber', '==', filter.phone).limit(1);
          case 'id':
            return ref.where('uid', '==', filter.id).limit(1);
          default:
            return ref.limit(1);
        }
      },
      { idField: 'id' },
    ).pipe(
      map((users) => {
        if (!users?.length) return null;

        return users[0];
      }),
    );
  }

  getConnectionIdStrings(userId: string): Observable<string[]> {
    return this.doc(userId)
      .collection<Connection>(DBUtil.Connections)
      .get()
      .pipe(
        take(1),
        map((ref) => {
          if (ref.empty) {
            return null;
          }
          return ref.docs.map((doc) => doc.id);
        }),
      );
  }

  getConnections(userId: string) {
    return this.getConnectionIdStrings(userId).pipe(
      switchMap((idStrs: string[]) => {
        if (!idStrs) return of([]);
        const users = idStrs.map((id) => {
          return this.getUserById$(id);
        });
        return zip(users);
      }),
    );
  }

  getUserDocRef(userID: string) {
    return this.doc(userID);
  }

  private _notNull = <T>(value?: T | null): value is T => !!value;
}

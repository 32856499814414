export interface Bounds {
  upperBound: number;
  bottomBound: number;
  rightBound: number;
  leftBound: number;
}

export const calculateBounds = (earthRadiusInMeters = 6378137) => {
  return (lat: number, lng: number, radius: number, inMiles = true): Bounds => {
    const radiusInMeters = inMiles ? radius * 1609.34 : radius;

    // Calculate latitude and longitude bounds using the radius
    const latDelta = (radiusInMeters / earthRadiusInMeters) * (180 / Math.PI);
    const lngDelta =
      (radiusInMeters / (earthRadiusInMeters * Math.cos((Math.PI * lat) / 180))) *
      (180 / Math.PI);

    // Return an object containing the bounds
    return {
      upperBound: lat + latDelta,
      bottomBound: lat - latDelta,
      rightBound: lng + lngDelta,
      leftBound: lng - lngDelta,
    };
  };
};

export const applyLocationBounds = (
  ref: any,
  bounds: Bounds,
  locationField: string = 'location',
  limit = 100,
) => {
  return ref
    .where(`${locationField}.lat`, '>=', bounds.bottomBound)
    .where(`${locationField}.lat`, '<=', bounds.upperBound)
    .where(`${locationField}.lng`, '<=', bounds.rightBound)
    .where(`${locationField}.lng`, '>=', bounds.leftBound)
    .limit(limit);
};

export const applyLocationMapsBounds = (
  ref: any,
  bounds: google.maps.LatLngBoundsLiteral,
  locationField: string = 'location',
  limit = 100,
) => {
  return ref
    .where(`${locationField}.lat`, '>=', bounds.south)
    .where(`${locationField}.lat`, '<=', bounds.north)
    .where(`${locationField}.lng`, '<=', bounds.east)
    .where(`${locationField}.lng`, '>=', bounds.west)
    .limit(limit);
};

export const generateBoundsFromLatLng = (lat: number, lng: number, rangeInMi = 10) => {
  const earthRadiusInMeters = 6378137;
  const radiusInMeters = rangeInMi * 1609.34;
  const latDelta = (radiusInMeters / earthRadiusInMeters) * (180 / Math.PI);
  const lngDelta =
    (radiusInMeters / (earthRadiusInMeters * Math.cos((Math.PI * lat) / 180))) *
    (180 / Math.PI);
  return {
    north: lat + latDelta,
    south: lat - latDelta,
    east: lng + lngDelta,
    west: lng - lngDelta,
  };
};

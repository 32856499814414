import 'firebase/firestore';

import { Availability } from '@index/interfaces';
import firebase from 'firebase/compat/app';

import {
  AdditionalInfo,
  Favorites,
  GthDuprRating,
  GthSportAvailability,
  PrivacySettings,
  User,
} from '../interfaces/user';
import { DefaultCity } from './../interfaces/user';
import { DBModel } from './model.db';

export class UserModel extends DBModel implements User {
  static readonly EMAIL = 'email';
  static readonly DISPLAY_NAME = 'displayName';
  static readonly FULL_NAME = 'fullName';
  static readonly DEFAULT_CITY = 'defaultCity';
  static readonly ADDITIONAL_INFO = 'additionalInfo';
  static readonly AVAILABILITY = 'availability';
  static readonly PRIVACY_SETTINGS = 'privacySettings';
  static readonly UID = 'uid';
  static readonly PHOTO_URL = 'photoURL';
  static readonly EMAIL_VERIFIED = 'emailVerified';
  static readonly TOKEN = 'token';
  static readonly NOTIFICATION_TOKENS = 'notificationTokens';
  static readonly FAVORITES = 'favorites';
  static readonly CREATED_AT = 'createdAt';
  static readonly UPDATED_AT = 'updatedAt';
  static readonly SPORTS_AVAILABILITY = 'sportAvailability';
  static readonly ONBOARDING_STEPS = 'onboardingSteps';
  static readonly DUPR_RATING = 'duprRating';
  static readonly STRIPE_CUSTOMER_ID = 'stripeCustomerId';
  static readonly SUBSCRIPTION = 'subscription';
  static readonly USER_SUBSCRIPTION = 'userSubscription';
  static readonly STRIPE_ID = 'stripeId';
  static readonly STRIPE_CHARGES_ENABLED = 'stripeChargesEnabled';
  static readonly STRIPE_DETAILS_SUBMITTED = 'stripeDetailsSubmitted';
  static readonly PHONE_NUMBER = 'phoneNumber';
  static readonly BIO = 'bio';
  static readonly FEEDBACK_SUBMITTED = 'feedbackSubmitted';

  constructor(
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
    public readonly email: string,
    public readonly displayName: string | undefined,
    public readonly fullName: string | undefined,
    public readonly defaultCity: DefaultCity | undefined,
    public readonly additionalInfo: AdditionalInfo | undefined,
    public readonly availability: Availability[] | undefined,
    public readonly privacySettings: PrivacySettings | undefined,
    public readonly uid: string,
    public readonly photoURL: string | undefined,
    public readonly emailVerified: boolean | undefined,
    public readonly token: string | undefined,
    public readonly favorites: Favorites | undefined,
    public readonly createdAt: firebase.firestore.Timestamp | undefined,
    public readonly updatedAt: firebase.firestore.Timestamp | undefined,
    public readonly sportAvailability: GthSportAvailability[] | undefined,
    public readonly onboardingSteps: string[] | undefined,
    public readonly duprRating: GthDuprRating | undefined,
    public readonly notificationTokens: string[] | undefined,
    public readonly stripeCustomerId: string | undefined,
    public readonly subscription: string | undefined,
    public readonly userSubscription: string | undefined,
    public readonly stripeId: string | undefined,
    public readonly stripeChargesEnabled: boolean | undefined,
    public readonly stripeDetailsSubmitted: boolean | undefined,
    public readonly phoneNumber: string | undefined,
    public readonly bio: string | undefined,
    public readonly feedbackSubmitted: boolean | undefined,
  ) {
    super(ref);
  }

  static fromJSON(
    user: User,
    ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  ) {
    return new UserModel(
      ref,
      user.email!,
      user.displayName!,
      user.fullName!,
      user.defaultCity,
      user.additionalInfo!,
      user.availability!,
      user.privacySettings!,
      user.uid,
      user.photoURL!,
      user.emailVerified,
      user.token,
      user.favorites,
      user.createdAt,
      user.updatedAt,
      user.sportAvailability,
      user.onboardingSteps,
      user.duprRating,
      user.notificationTokens,
      user.stripeCustomerId ?? undefined,
      user.subscription ?? undefined,
      user.userSubscription ?? undefined,
      user.stripeId ?? undefined,
      user.stripeChargesEnabled ?? false,
      user.stripeDetailsSubmitted ?? false,
      user.phoneNumber ?? '',
      user.bio ?? '',
      user.feedbackSubmitted,
    );
  }

  get createdBeforeFirstLogIn(): boolean {
    return !!this.email && !this.displayName && !this.photoURL && !this.emailVerified;
  }

  get gender(): string | null {
    if (!this.additionalInfo || !this.additionalInfo.gender) return null;
    return this.additionalInfo.gender;
  }
}

import {
  AdditionalInfo,
  Availability,
  Endorsement,
  EndorsementType,
  GthSportAvailability,
  PrivacySettings,
  User,
} from '@index/interfaces';
import { GUEST_PROFILE_ID } from '@shared/services/guest-user.service';
import moment from 'moment-timezone';

import { GthModel } from './model';
import { SrvAvailabilityModel } from './user-availability';

export class GthUserModel extends GthModel<User> {
  constructor(id: string, model: User) {
    // if(!id) {
    //   // This helps the emulators, which don't have a id field
    //   if(!model.uid) {
    //     throw new Error('model must have an id or uid');
    //   }
    //   id = model.uid;
    // }
    super(id || 'temp', model);
  }

  get srvAvailabilityModel() {
    return this.model.srvAvailabilityModel || null;
  }

  get user() {
    return this.model;
  }

  get hasAdditionalInfo(): boolean {
    return !!this.user.additionalInfo;
  }

  get duprId(): string {
    return this.model.duprId;
  }

  get joinReason() {
    return this.model.joinReason;
  }

  get hasDuprId(): boolean {
    return !!this.model.duprId;
  }

  get additionalInfo(): AdditionalInfo | undefined {
    if (!this.user.additionalInfo) return undefined;
    return this.user.additionalInfo;
  }

  get id(): string {
    return this._id;
  }

  get uid(): string {
    return this.model.uid;
  }

  get email() {
    return this.user.email;
  }

  get isGuestUser() {
    return this.id === GUEST_PROFILE_ID;
  }

  get notificationSettings() {
    return this.model.notificationSettings;
  }

  get feedbackSubmitted() {
    return this.model.feedbackSubmitted;
  }

  get hasNotificationSettings() {
    return this.model.notificationSettings;
  }

  getCheckInsForPlaceToday(id: string) {
    return (this.model.checkIns || []).filter((checkIn)=> {
      const cIDate = new Date(checkIn.time['seconds'] *1000);
      const isSameDay =
      moment(cIDate).isSame(Date.now(), 'day');
      return checkIn.placeId === id && isSameDay;
    });
  }

  getCheckInsForActivity(activity: string) {
    return (this.model.checkIns || []).filter((checkIn)=>{
      return checkIn.type === activity;
    });
  }

  getAllCheckIns() {
    return this.model.checkIns || [];
  }
  // getCheckInsForActivity(activity: string){
  //   return this.sportAvailability.filter((sport)=>{
  //     return sport.skill === activity;
  //   });
  // }

  get token() {
    return this.model.token;
  }
  set token(val: string | undefined) {
    this.model.token = val;
  }

  get notificationTokens() {
    return this.model.notificationTokens ?? [];
  }
  set notificationTokens(val: string[]) {
    this.model.notificationTokens = val;
  }

  get team() {
    return this.model.team;
  }
  set team(val: {
    id: string,
    name: string,
    photoURL: string,
  } | undefined) {
    this.model.team = val;
  }

  get photoURL() {
    return this.user.photoURL ?? '';
  }
  set photoURL(val: string) {
    this.user.photoURL = val;
  }

  get onboardingSteps() {
    return this.user.onboardingSteps ?? [];
  }
  set onboardingSteps(val: string[]) {
    this.user.onboardingSteps = val;
  }

  get fullName() {
    return this.user.fullName ?? '';
  }
  set fullName(val: string) {
    this.user.fullName = val;
  }

  get displayName() {
    return this.user.displayName ?? '';
  }
  set displayName(val: string) {
    this.user.displayName = val;
  }

  get displayNameFallback() {
    if (this.displayName) {
      return this.displayName;
    }
    if (this.fullName) {
      return this.fullName;
    }
    return this.email;
  }


  get duprSingles() {
    return this.user.duprRating?.single ?? -1;
  }

  get duprDoubles() {
    return this.user.duprRating?.double ?? -1;
  }

  get emailVerified() {
    return this.user.emailVerified;
  }

  get gender() {
    if (!this.hasAdditionalInfo) return 'Any';
    return this.user.additionalInfo!.gender || 'Any';
  }

  get sports() {
    if (!this.hasAdditionalInfo) return '';
    return this.user.additionalInfo!.sports;
  }

  get sportsStr() {
    if (!this.sportAvailability?.length) return '';
    return this.user.sportAvailability
      .map((s) => s.sport)
      .filter(Boolean)
      .join(', ');
  }

  get pronoun() {
    if (!this.hasAdditionalInfo) return '';
    return this.user.additionalInfo!.pronoun;
  }

  get defaultCity() {
    return this.user.defaultCity;
  }

  get defaultCityName() {
    return this.user.defaultCity?.name ?? '';
  }

  get availability() {
    return this.user.availability ?? null;
  }
  set availability(availability: Availability[] | null) {
    this.user.availability = availability;
  }

  get sportAvailability() {
    return this.user.sportAvailability ?? undefined;
  }
  set sportAvailability(sportAvailability: GthSportAvailability[] | undefined) {
    this.user.sportAvailability = sportAvailability;
  }

  get privacySettings() {
    if (!this.user.privacySettings) {
      return {
        blocklist: [''],
        messagesFromAnyone: true,
        publicBadges: true,
        publicOnlineStatus: true,
        publicUpcomingGames: true,
      };
    }
    return this.user.privacySettings;
  }
  set privacySettings(privacySettings: PrivacySettings | null) {
    this.user.privacySettings = privacySettings;
  }

  get favorites() {
    return {
      teams: this.user.favorites?.teams ?? [],
      tournaments: this.user.favorites?.tournaments ?? [],
    };
  }
  set favorites(val: User['favorites']) {
    this.user.favorites = val;
  }

  get ratings() {
    return this.user.ratings ?? [];
  }

  get ratingWeights() {
    const totals: any = {};
    this.ratings.forEach((r) => {
      const rating = totals[r.rating];
      if (rating) {
        rating.count++;
      } else {
        totals[r.rating] = {
          ...r,
          count: 0,
        };
      }
    });
    const arr = [];
    // eslint-disable-next-line guard-for-in
    for (const key in totals) {
      const rating = totals[key];
      arr.push(rating);
    }
    arr.sort((a, b) => a.count - b.count);
    return arr;
  }

  get createdAt() {
    return this.user.createdAt;
  }

  get updatedAt() {
    return this.user.updatedAt;
  }

  setDuprId(duprId: number) {
    this.user.duprRating = {
      id: duprId.toString(),
    };
  }

  setDuprRatings(ratings: string[]) {
    const id = this.user.duprRating?.id ?? '';
    this.user.duprRating = {
      id,
      double: ratings[0],
      single: ratings[1],
    };
  }

  get stripeCustomerId() {
    return this.user.stripeCustomerId ?? '';
  }
  set stripeCustomerId(val: string) {
    this.user.stripeCustomerId = val;
  }

  get subscription() {
    return this.user.subscription ?? 'Free';
  }
  set subscription(val: string) {
    this.user.subscription = val;
  }

  get userSubscription() {
    return this.user.userSubscription ?? 'Free';
  }
  set userSubscription(val: string) {
    this.user.userSubscription = val;
  }

  get stripeId() {
    return this.user.stripeId ?? '';
  }
  set stripeId(val: string) {
    this.user.stripeId = val;
  }

  get stripeChargesEnabled() {
    if (!this.user.stripeChargesEnabled) return false;
    return this.user.stripeChargesEnabled;
  }
  set stripeChargesEnabled(val: boolean) {
    this.user.stripeChargesEnabled = val;
  }

  get stripeDetailsSubmitted() {
    if (!this.user.stripeDetailsSubmitted) return false;
    return this.user.stripeDetailsSubmitted;
  }
  set stripeDetailsSubmitted(val: boolean) {
    this.user.stripeDetailsSubmitted = val;
  }

  get phoneNumber() {
    return this.user.phoneNumber ?? '';
  }
  set phoneNumber(val: string) {
    this.user.phoneNumber = val;
  }

  get bio() {
    return this.user.bio ?? '';
  }
  set bio(val: string) {
    this.user.bio = val;
  }

  private _connections: string[] = [];
  get connections() {
    return this._connections;
  }
  set connections(val: string[]) {
    this._connections = val;
  }
  isConnected(userId: string) {
    return this._connections?.includes(userId);
  }
  connect(userId: string) {
    if (this.isConnected(userId)) return;
    this._connections.push(userId);
  }
  unconnect(userId: string) {
    const index = this._connections?.indexOf(userId);
    if (index === -1) return;
    this._connections.splice(index, 1);
  }

  private _endorsements: Endorsement[] = [];
  get endorsements() {
    return this._endorsements;
  }
  set endorsements(val: Endorsement[]) {
    this._endorsements = val;
  }

  get passionEndorsements() {
    return this._endorsements.filter((e) => e.type === EndorsementType.PASSION);
  }
  get personalityEndorsements() {
    return this._endorsements.filter((e) => e.type === EndorsementType.PERSONALITY);
  }

  private _userAvailability?: SrvAvailabilityModel;
  get userAvailability() {
    return this._userAvailability;
  }
  set userAvailability(val: SrvAvailabilityModel | undefined) {
    this._userAvailability = val;
  }

  getModel() {
    return this.model;
  }

  addRatings(user: User, ratings: number[]) {
    let userRatings = this.ratings;
    userRatings = userRatings.concat(
      ratings.map((r) => {
        return {
          userId: user.uid,
          rating: r,
        };
      }),
    );
    this.user.ratings = userRatings;
  }

  favoriteTournament(tournamentId: string) {
    const tournaments = this.user.favorites?.tournaments ?? [];
    if (!tournaments.includes(tournamentId)) {
      tournaments.push(tournamentId);
    }

    this.user.favorites = {
      tournaments,
      teams: this.user.favorites?.teams ?? [],
    };
  }
  unFavoriteTournament(tournamentId: string) {
    let tournaments = this.user.favorites?.tournaments ?? [];
    if (tournaments.includes(tournamentId)) {
      tournaments = tournaments.filter((t) => t !== tournamentId);
    }

    this.user.favorites = {
      tournaments,
      teams: this.user.favorites?.teams ?? [],
    };
  }
  favoriteTeam(teamId: string) {
    const teams = this.user.favorites?.teams ?? [];
    if (!teams.includes(teamId)) {
      teams.push(teamId);
    }

    this.user.favorites = {
      tournaments: this.user.favorites?.tournaments ?? [],
      teams,
    };
  }
  unFavoriteTeam(teamId: string) {
    let teams = this.user.favorites?.teams ?? [];
    if (teams.includes(teamId)) {
      teams = teams.filter((t) => t !== teamId);
    }

    this.user.favorites = {
      tournaments: this.user.favorites?.tournaments ?? [],
      teams,
    };
  }

  hasPaymentsSetup() {
    return (
      !!this.user?.stripeId &&
      this.user?.stripeChargesEnabled &&
      this.user?.stripeDetailsSubmitted
    );
  }
}

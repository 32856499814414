import firebase from 'firebase/compat/app';

import { EventItemTypes } from '../enums/event-item-type';
import { Mapper } from '../interfaces/mapper';
import { EventItemModel } from '../models/event-item';

export class EventItemMapper implements Mapper<EventItemModel> {
  fromSnapshot(snapshot: firebase.firestore.DocumentSnapshot): EventItemModel | undefined {
    if (snapshot === null || snapshot === undefined) return undefined;
    const data = snapshot.data();
    if (data === null || data === undefined) return undefined;

    return new EventItemModel(
      snapshot.ref as any,
      data[EventItemModel.CREATOR],
      data[EventItemModel.TITLE],
      data[EventItemModel.DATE_START],
      data[EventItemModel.DURATION],
      data[EventItemModel.LOCATION],
      data[EventItemModel.HOSTING_TEAM],
      data[EventItemModel.COST],
      data[EventItemModel.MINIMUM_NEEDED],
      data[EventItemModel.HOURS_TO_CANCEL_BEFORE],
      data[EventItemModel.EQUIPMENT_NEEDED],
      data[EventItemModel.MAKE_PUBLIC_AFTER],
      data[EventItemModel.EVENT_SLOT_GROUP],
      data[EventItemModel.CREATOR_APPROVAL_NEEDED],
      data[EventItemModel.ALLOW_PARTICIPANT_GUESTS],
      data[EventItemModel.TYPE],
      data[EventItemModel.BANNER],
      data[EventItemModel.CANCELLED],
      data[EventItemModel.UPDATED],
      data[EventItemModel.RATED],
      data[EventItemModel.ONLINE],
      data[EventItemModel.DESCRIPTION],
      data[EventItemModel.GAME_TYPE],
      data[EventItemModel.SKILL_LEVELS],
      data[EventItemModel.DISCOVERABLE],
      data[EventItemModel.THEME],
      data[EventItemModel.BACKGROUND_COLOR],
      data[EventItemModel.SEND_FEEDBACK_EMAIL_AFTER],
      data[EventItemModel.TICKET_LEVELS],
      data[EventItemModel.PLATFORM],
      data[EventItemModel.PRICE_ID],
      data[EventItemModel.ALLOW_MAYBE_RSVP],
      data[EventItemModel.REQUIRE_GUEST_INFORMATION],
    );
  }

  toMap(item: EventItemModel): firebase.firestore.DocumentData {
    const mapped = {
      [EventItemModel.CREATOR]: item.creator,
      [EventItemModel.TITLE]: item.title,
      [EventItemModel.DATE_START]: item.dateStart,
      [EventItemModel.DURATION]: item.duration,
      [EventItemModel.LOCATION]: item.location,
      [EventItemModel.HOSTING_TEAM]: item.hostingTeam,
      [EventItemModel.COST]: item.cost,
      [EventItemModel.MINIMUM_NEEDED]: item.minimumNeeded,
      [EventItemModel.HOURS_TO_CANCEL_BEFORE]: item.hoursToCancelBefore,
      [EventItemModel.EQUIPMENT_NEEDED]: item.equipmentNeeded,
      [EventItemModel.MAKE_PUBLIC_AFTER]: item.makePublicAfter,
      [EventItemModel.EVENT_SLOT_GROUP]: item.eventSlotGroup ?? [],
      [EventItemModel.CREATOR_APPROVAL_NEEDED]: item.creatorApprovalNeeded ?? false,
      [EventItemModel.ALLOW_PARTICIPANT_GUESTS]: item.allowParticipantGuests ?? false,
      [EventItemModel.TYPE]: item.type || EventItemTypes.Pickup,
      [EventItemModel.BANNER]: item.banner,
      [EventItemModel.CANCELLED]: item.cancelled ?? false,
      [EventItemModel.UPDATED]: item.updated,
      [EventItemModel.RATED]: item.rated,
      [EventItemModel.ONLINE]: item.online ?? false,
      [EventItemModel.DESCRIPTION]: item.description ?? '',
      [EventItemModel.GAME_TYPE]: item.gameType ?? '',
      [EventItemModel.SKILL_LEVELS]: item.skillLevels ?? [],
      [EventItemModel.DISCOVERABLE]: item.discoverable ?? false,
      [EventItemModel.THEME]: item.theme ?? null,
      [EventItemModel.BACKGROUND_COLOR]: item.backgroundColor ?? null,
      [EventItemModel.SEND_FEEDBACK_EMAIL_AFTER]: item.sendFeedbackEmailAfter ?? false,
      [EventItemModel.TICKET_LEVELS]: item.ticketLevels ?? [],
      [EventItemModel.SURVEY]: item.survey ?? null,
      [EventItemModel.PLATFORM]: item.platform ?? '',
      [EventItemModel.PRICE_ID]: item.priceId ?? '',
      [EventItemModel.ALLOW_MAYBE_RSVP]: item.allowMaybeRsvp ?? false,
      [EventItemModel.REQUIRE_GUEST_INFORMATION]: item.requireGuestInformation ?? false,
    };
    return mapped;
  }
}

import firebase from 'firebase/compat/app';

import { Mapper } from '../interfaces/mapper';
import { UserModel } from '../models/user';

export class UserMapper implements Mapper<UserModel> {
  fromSnapshot(snapshot: firebase.firestore.DocumentSnapshot): UserModel | undefined {
    if (snapshot === null || snapshot === undefined) return undefined;
    const data = snapshot.data();
    if (data === null || data === undefined) return undefined;

    return new UserModel(
      snapshot.ref as any,
      data[UserModel.EMAIL],
      data[UserModel.DISPLAY_NAME],
      data[UserModel.FULL_NAME],
      data[UserModel.DEFAULT_CITY],
      data[UserModel.ADDITIONAL_INFO],
      data[UserModel.AVAILABILITY],
      data[UserModel.PRIVACY_SETTINGS],
      data[UserModel.UID],
      data[UserModel.PHOTO_URL],
      data[UserModel.EMAIL_VERIFIED],
      data[UserModel.TOKEN],
      data[UserModel.FAVORITES],
      data[UserModel.CREATED_AT],
      data[UserModel.UPDATED_AT],
      data[UserModel.SPORTS_AVAILABILITY],
      data[UserModel.ONBOARDING_STEPS],
      data[UserModel.DUPR_RATING],
      data[UserModel.NOTIFICATION_TOKENS],
      data[UserModel.STRIPE_CUSTOMER_ID],
      data[UserModel.SUBSCRIPTION],
      data[UserModel.USER_SUBSCRIPTION],
      data[UserModel.STRIPE_ID],
      data[UserModel.STRIPE_CHARGES_ENABLED],
      data[UserModel.STRIPE_DETAILS_SUBMITTED],
      data[UserModel.PHONE_NUMBER],
      data[UserModel.BIO],
      data[UserModel.FEEDBACK_SUBMITTED],
    );
  }

  toMap(item: UserModel): firebase.firestore.DocumentData {
    return {
      [UserModel.EMAIL]: item.email,
      [UserModel.DISPLAY_NAME]: item.displayName,
      [UserModel.FULL_NAME]: item.fullName,
      [UserModel.DEFAULT_CITY]: item.defaultCity,
      [UserModel.ADDITIONAL_INFO]: item.additionalInfo,
      [UserModel.AVAILABILITY]: item.availability,
      [UserModel.PRIVACY_SETTINGS]: item.privacySettings? item.privacySettings : null,
      [UserModel.UID]: item.uid,
      [UserModel.PHOTO_URL]: item.photoURL,
      [UserModel.EMAIL_VERIFIED]: item.emailVerified,
      [UserModel.TOKEN]: item.token ?? '',
      [UserModel.FAVORITES]: item.favorites ?? null,
      [UserModel.CREATED_AT]: item.createdAt ?? null,
      [UserModel.UPDATED_AT]: item.updatedAt ?? null,
      [UserModel.SPORTS_AVAILABILITY]: item.sportAvailability ?? [],
      [UserModel.ONBOARDING_STEPS]: item.onboardingSteps ?? [],
      [UserModel.DUPR_RATING]: item.duprRating ?? null,
      [UserModel.NOTIFICATION_TOKENS]: item.notificationTokens ?? [],
      [UserModel.STRIPE_CUSTOMER_ID]: item.stripeCustomerId ?? null,
      [UserModel.SUBSCRIPTION]: item.subscription ?? 'Free',
      [UserModel.USER_SUBSCRIPTION]: item.userSubscription ?? 'Free',
      [UserModel.STRIPE_ID]: item.stripeId ?? null,
      [UserModel.STRIPE_CHARGES_ENABLED]: item.stripeChargesEnabled ?? null,
      [UserModel.STRIPE_DETAILS_SUBMITTED]: item.stripeDetailsSubmitted ?? null,
      [UserModel.PHONE_NUMBER]: item.phoneNumber ?? null,
      [UserModel.BIO]: item.bio ?? null,
      [UserModel.FEEDBACK_SUBMITTED]: item.feedbackSubmitted ?? null,
    };
  }
}
